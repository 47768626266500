.box-circle{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: white;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    display: flex;
    margin-top: 5px;
}

.text-user{
    margin-bottom: 0px;
    font-size: 30px;
    color: #2a5c78 !important;        
}
